.btn {
  -webkit-appearance: none;
  border: 0 none;
  display: inline-block;
  text-decoration: none;

  &--ok {
    align-self: center;
    background: var(--secondary-color);
    color: var(--text-color);
    font: 16px/26px var(--secondary-font);
    height: 25px;
    text-align: center;
    text-transform: uppercase;
    width: 30px;
    border-radius: 4px;
  }

  &--submit {
    background: var(--secondary-color);
    border-radius: 4px;
    color: var(--text-color);
    font: 16px/40px var(--secondary-font);
    height: 50px;
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;
    width: 230px;
  }

  &--save {
    background: var(--secondary-color);
    border-radius: 4px;
    color: var(--text-color);
    font: 16px/40px var(--secondary-font);
    height: 50px;
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;
    width: 170px;
  }

  &--big-rounded {
    background: #e1e1e1;
    border-radius: 5px;
    color: var(--primary-color);
    font: 14px/30px var(--secondary-font);
    height: 30px;
    padding: 0 30px;
    text-align: center;
    text-transform: uppercase;
  }
}
