.inner {
  margin: 0 auto;
  max-width: 1242px;
  width: calc(100% - 40px);
}

.auto {
  margin: 0 auto;
}

.none {
  display: none;
}
