.footer {
  background: var(--primary-color);
  border-top: 3px solid var(--secondary-color);
  bottom: 0px;
  display: flex;
  height: 60px;
  left: 0px;
  padding: 0 15px;
  position: fixed;
  width: 100%;
  z-index: 2;
  &__no-stick {
    position: relative;
    height: auto;
    background-color: #001e3c;
  }
}

.App--desktop {
  .footer:not(.footer__no-stick) {
    position: absolute;
  }
}

.legal {
  max-width: 1440px;
  margin: 0 auto;
  font-family: Helvetica,sans-serif;
  font-weight: 700;
  font-size: .75rem;
  line-height: 1;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px 25px 50px;

  &__navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  &__copyright {
    color: #a0a1a1;
    white-space: nowrap;
    padding-top: 20px;
    text-align: center;
    text-transform: uppercase;
  }
  &__link {
    text-decoration: none;
    padding-top: 20px;
    text-align: center;
    text-transform: uppercase;
    white-space: wrap;
    color: #fff;
    &:active, &:hover, &:visited {
    color: #fff;
      text-decoration: none;
    }
  }
}

.footerOptions {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 0 auto;

  a, button:not(.btn) {
    appearance: none;
    align-self: center;
    background: transparent;
    box-shadow: none;
    border: 0 none;
    color: var(--text-color);
    font: 14px/1.3 var(--secondary-font);
    margin: 0 25px;
    padding: 0;
    text-decoration: none;
    text-transform: uppercase;

    span {
      display: inline-block;
      height: 15px;
      margin-right: 6px;
      position: relative;
      top: -2px;

      img {
        height: 100%;
      }
    }
  }

  .btn {
    &--ok {
      width: auto;
      height: auto;
      padding: 0px 15px;
      line-height: 2em;
    }
  }
}

.colorPicker {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &Label {
    align-self: center;
    color: var(--text-color);
    font: 14px/1.3 var(--secondary-font);
    margin-right: 10px;
    text-transform: uppercase;

    span {
      align-self: center;
      display: inline-block;
      margin-right: 8px;
      position: relative;
      top: -1px;

      img {
        height: 19px;
      }
    }
  }

  .color {
    align-self: center;
    display: flex;
    justify-content: space-between;

    &Item {
      height: 20px;
      margin: 0 4px;
      overflow: hidden;
      position: relative;
      width: 20px;

      .colorLabel {
        border: 3px solid var(--primary-color);
        border-radius: 50%;
        display: inline-block;
        height: 20px;
        transition: border-color 200ms ease-in-out;
        width: 20px;
        z-index: 1;

        &--white {
          background: #ffffff;
        }

        &--blue {
          background: #639df1;
        }

        &--red {
          background: #b52541;
        }

        &--green {
          background: #3cb878;
        }

        &--purple {
          background: #8781bd;
        }

        &--yellow {
          background: #ffd500;
        }
      }

      input[type='radio'] {
        left: 2px;
        opacity: 0;
        position: absolute;
        top: 2px;
        z-index: 0;
      }

      input[type='radio']:checked ~ label {
        border-color: rgba(#ffffff, 0.2);
      }
    }
  }
}

.sliderOption {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .footerLabel  {
    align-self: center;
    color: var(--text-color);
    font: 14px/1.3 var(--secondary-font);
    flex: .5;
    margin-right: 10px;
    text-transform: uppercase;

    span {
      align-self: center;
      display: inline-block;
      margin-right: 8px;
      position: relative;
      top: -1px;

      img {
        height: 15px;
      }
    }
  }

  .slider {
    align-items: center;
    display: flex;
    flex: 5;
    width: 100%;

    .input-range__slider {
      background: #fff !important;
      border-color: #fff !important;
    }

    .input-range__track {
      background: #fff !important;
    }

    .input-range__track, .input-range__slider-container {
      transition: none;
    }

    // .sliderTrack {
    //   appearance: none !important;
    //   -webkit-appearance: none !important;
    //   background: #ffffff;
    //   border-radius: 0;
    //   height: 3px;
    //   outline: none;
    //   width: 100%;

    //   &::-webkit-slider-thumb {
    //     appearance: none !important;
    //     -webkit-appearance: none !important;
    //     background: #ffffff;
    //     border-radius: 50%;
    //     height: 20px;
    //     width: 20px;
    //     z-index: 999;
    //   }

    //   &::-moz-range-thumb {
    //     appearance: none !important;
    //     background: #ffffff;
    //     border-radius: 50%;
    //     cursor: pointer;
    //     height: 20px;
    //     width: 20px;
    //   }
    // }
  }

  .action {
    align-items: center;
    display: flex;
    flex: .5;
    margin-left: 15px;
  }
}
