.submit {
  animation: slideIn 1s forwards 500ms;
  background: rgba(#000000, 0.2);
  bottom: 0;
  min-height: 165px;
  left: 0;
  padding: 20px 10px;
  position: absolute;
  text-align: center;
  transform: translateY(165px);
  width: 100%;
  z-index: 1000;
  .subtext {
    color: var(--text-color);
    font: 16px/1.5 var(--secondary-font);
    letter-spacing: -0.5px;
    margin-top: 10px;

    a {
      color: #007eff;
      text-decoration: underline;
    }
  }
}

.gdpr {
  color: var(--text-color);
  font: 12px/1.4 var(--body-font);
  margin-bottom: 25px;
  text-align: left;

  a {
    color: var(--secondary-color);
  }

  &--has-checkbox {
    display: flex;
    justify-content: space-between;

    .gdprCheckboxLabel {
      border: 1px solid var(--text-color);
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      height: 30px;
      position: relative;
      width: 30px;

      img, svg {
        align-self: center;
        opacity: 0;
        transition: opacity 200ms ease;
        width: 20px;
      }
    }

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;

      &:checked ~ .gdprCheckboxLabel {
        svg, img {
          opacity: 1;
        }
      }
    }
  }

  .gdprCheckbox {
    width: 40px;
  }

  .gdprContent {
    width: calc(100% - 50px);
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(165px);
  }
  100% {
    transform: translateY(0);
  }
}
