@keyframes scale-in-tr {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

// Variables
$bar_color: #fff;
$size: 20px;
$bar_height: $size / 8;
$offset: $bar_height * 2;

// Mixin
@mixin bar {
  display: block;
  width: $size;
  height: $bar_height;
  background: $bar_color;
  border-radius: 2px;
}

.header {
  background: var(--primary-color);
  border-top: 4px solid var(--secondary-color);
  display: flex;
  height: 44px;
  padding: 0 10px;
  position: relative;

  .headerBranding {
    align-self: center;
    height: 34px;
    justify-self: flex-start;

    img {
      height: 100%;
    }
  }

  .headerNav {
    align-self: center;
    color: var(--text-color);
    display: flex;
    margin: 0 auto;
    padding-right: 40px;

    &Item {
      // width: 20px;
      align-self: center;
      color: #fff;
      font: 12px/1 var(--secondary-font);
      text-decoration: none;
      text-transform: uppercase;

      span {
        align-self: center;
        display: inline-block;
        margin: 0 5px;
        vertical-align: middle;
      }

      img {
        display: inline-block;
        height: 10px;
        transform-origin: center center;
        width: 14px;
      }

      &--next {
          img {
            transform: rotate(180deg);
          }
      }
    }

    h1 {
      font: 16px/1.2 var(--secondary-font);
      line-height: 1.2;
      margin: 0 15px;
      text-transform: uppercase;
    }
  }

}

.headerMenuToggle {
  appearance: none;
  background: transparent;
  border: 0 none;
  align-self: center;
  display: block;
  height: ($offset*2) + $bar_height;
  outline: none;
  position: absolute;
  right: 15px;
  width: $size;
  cursor: pointer;
  top: 14px;

  i {
    @include bar;

    position: absolute;
    top: 50%; left: 0;
    margin-top: -($bar_height/2);
    transition: all .2s linear;

    &:before,
    &:after {
      @include bar;

      content: "";
      position: absolute;
      transition: .2s linear;
    }

    &:before { top: -$offset; }
    &:after { bottom: -$offset; }

    &.active {
      background-color: transparent;
      transition: all .2s linear;

      &:before {
        top: 0;
        transform: rotate(45deg);
      }

      &:after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }

}

.menu {
  background: var(--secondary-color);
  overflow: hidden;
  padding: 15px 20px;
  position: absolute;
  right: 0;
  top: 40px; // header height (44px - border 4px)
  transform: scale(0);
  z-index: 99;
  display: -webkit-flex; /* Safari */
  flex-direction: column;
  align-items:flex-end;

  .menuItem {
    color: #fff;
    display: block;
    font: 400 36px/1.5 var(--primary-font);
    text-align: right;
    text-decoration: none;
    text-transform: uppercase;
    background: transparent;
    border:none;
  }

  &.active {
    animation: scale-in-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
}
