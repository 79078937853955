.text {
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
  width: calc(100% - 40px);
  margin-bottom: 10px;

  p {
    color: var(--text-color);
    font: 11px/1.2 var(--body-font);
    margin-top: 0;
    font-weight: 600;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  h3 {
    bottom: 0;
    color: var(--text-color);
    font: 16px/1.2 var(--secondary-font);
    letter-spacing: -0.3px;
    margin: 0 0 20px;
  }

  a {
    color: #fff;
  }

  &--thin p {
    // font-family: var(--light-font);
    font-weight: lighter;
  }

  &--small p {
    font-size: 9px !important;
  }

  &--large p {
    font-size: 12px !important;
  }
}
