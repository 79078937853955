* {
  box-sizing: border-box;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

html {
  background-color: var(--primary-color);
  font-size: 1em;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

body {
  width: 100%;
}

.App {
  background-position: center bottom;
  background-size: cover;
  min-height: calc(100vh - 60px);
  overflow-y: auto;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
img,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

/* ==========================================================================
   Browse Happy prompt
   ========================================================================== */

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
