.modal {
   position: absolute;
   top: 0px;
   left: 0;
   padding: 15px;
   background-color: white;
   z-index: 100;
   height: 100vh;
   display: none;
   overflow-y: scroll;
   span {
    background-color: white;
   }
   table {
     border: 1px black solid;
     td {
      border: 1px black solid;
      padding: 2px;
     }
   }
   strong {
     font-weight: 900;
   }
   &--prizes {
     overflow-y: hidden;
   }
}
.active {
  display: block;
}


.App--desktop {
  .modal.active {
    display: block;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.6rem;
  }
}
