@import "../Tools/tools.media-queries.scss";

.desktopLanding {
  height: 100vh;
  overflow: hidden;
  width: 100vw;

  .desktopLandingBackground {
    height: 100vh;
    left: 0;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1;
  }

  .desktopLandingCan {
    bottom: 0px;
    display: none;
    height: calc(100% - 20px);
    left: 0;
    z-index: 2;
    max-width: 700px;
    overflow: hidden;
    position: absolute;
    transform-origin: center bottom;
    transform: translateX(-60px) scale(0.9);
    width: calc(100% - 40px);

    @include min-screen(1024px) {
      display: block;
    }

    @include min-screen(1400px) {
      transform: translateX(-60px) scale(1);
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .desktopLandingPhone {
    bottom: 0;
    left: 50%;
    height: 782px;
    width: 800px;
    position: absolute;
    transform: translateX(-72%);
    transform-origin: left bottom;
    z-index: 3;

    .desktopLandingPhoneAppContainer {
      background: #0f0069;
      height: 620px;
      right: 81px;
      position: absolute;
      top: 17px;
      width: 290px;
      overflow: scroll;

      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .desktopLandingPhoneAppHand {
      height: 100%;
      pointer-events: none;
      position: relative;
      width: 100%;
      z-index: 1000;

      img {
        display: block;
        max-height: 100%;
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }

  .desktopLandingQR {
    display: none;
    height: (194px/1920px) * 100vw;
    bottom: 20px;
    position: absolute;
    right: 20px;
    transform-origin: center bottom;
    width: (415px/1920px) * 100vw;
    z-index: 4;

    @include min-screen(1024px) {
      display: block;
    }

    @include min-screen(1300px) {
      bottom: (175px/1920px) * 100vw;
      right: (115px/1920px) * 100vw;
    }

    img {
      width: 100%;
    }
  }

  // .desktopLandingCan,
  // .desktopLandingCan > *,
  // .desktopLandingPhone,
  // .desktopLandingPhone > *,
  // .desktopLandingQR,
  // .desktopLandingQR > * {
  //   transform-origin: center bottom;

  //   @include max-screen-height(600px) {
  //     transform: scale(0.8);
  //   }
  // }
}
