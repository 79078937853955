.rotateOverlay {
  z-index: 999999;
  position: fixed;
  background: rgba(0,0,0,0.8);
  height: 100%;
  width: 100%;
  color: #fff;
  pointer-events: none;

  h1 {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
  }
}
