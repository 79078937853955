.share {
  background-position: center center;
  background-size: cover;
  height: auto;
  overflow: hidden;
  padding-bottom: 60px;
  width: 100%;

  .preview {
    border: 3px solid rgba(#ffffff, 0.3);
    // margin: 50px 30px 20px;
    margin: 50px auto 20px auto;
    position: relative;

    .media {
      height: 100% !important;
    }
  }

  &Actions {
    color: var(--text-color);
    margin: 0 auto;
    text-align: center;
    width: calc(100% - 60px);

    h2, h3 {
      margin: 0;
    }

    h2 {
      font: 30px/1.2 var(--primary-font);
    }

    h3 {
      font: 20px/1.2 var(--body-font);
    }

    small {
      display: block;
      font: 14px/1.2 var(--body-font);
      margin: 15px 0;
    }

    button.startNew {
      color: var(--text-color);
      display: inline-block;
      font: 14px/1 var(--body-font);
      letter-spacing: -0.5px;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;

      &:after {
        background: var(--secondary-color);
        content: "";
        display: block;
        height: 2px;
        position: relative;
        top: 2px;
        width: 100%;
      }
    }
  }
}

.socialShare {
  display: flex;
  justify-content: space-around;
  margin: 20px auto;
  max-width: calc(100% - 40px);
  width: 240px;

  &Item {
    display: block;
    height: 50px;
    width: 50px;

    img {
      width: 100%;
    }
  }
}
