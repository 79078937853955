.startPage {
  background-color: #323b7c;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 100vh;
  height: 100%;
}

.App--desktop {
  .startPage {
    height: auto !important;
  }
}
