.draggable {
  border:5px dashed transparent;
  display: inline-block;
  max-width: calc(100% - 40px);
  min-height: auto;
  min-width: 100px;
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;

  &--label {
    position: absolute;
    bottom: -32px;
    left: 0;
    width: 100%;
    color: #fff;
    font-size: 15px;
    text-align: center;
    font-family: var(--primary-font);
  }

  &.focused {
    border:5px dashed #ffffff;
  }
  &--item {
    width: 128px;
    height: 128px;

    &--variable-height {
      height: auto !important;
    }

    &--text {
      width: 256px;
    }

    img {
      width: 100%
    }
  }
  p {
    color: #ffffff;
    line-height: 1.2;
    font-family: var(--primary-font);
    margin: 0;
    word-break: break-all;
  }

  .draggableSticker {
    display: block;
    margin: 0 auto;
  }

  &Remove {
    -webkit-appearance: none;

    background: #ffffff;
    border-radius: 50%;
    border: 0 none;
    display: block;
    height: 30px;
    padding: 0;
    position: absolute;
    right: -15px;
    top: -15px;
    width: 30px;

    img {
      align-self: center;
      display: block;
      height: 12px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
    }
  }
}
