.gallery {
  height: auto;
  width: 100%;
  position: relative;

  h2 {
    color: var(--text-color);
    font: 14px/1 var(--primary-font);
    grid-column-start: 1;
    grid-column-end: 4;
    letter-spacing: letter-spacing(-50, 14px);
    margin-bottom: 0;
    text-transform: uppercase;
  }

  &--single {
    min-height: 100vh;
  }

  &Grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0px;

    &--inline {
      grid-template-columns: 1fr 1fr 1fr;

      .galleryItem {
        background-color: #dadada;
      }
    }

    &--large-right {
      grid-template-columns: 1fr 1fr 1fr;

      .galleryItem:nth-child(2) {
        background-color: #d2003c;
        grid-row: 1 / span 2;
        grid-column: 2 / span 2;
        height: 100%;
      }

      .galleryItem:nth-child(3) {
        background-color: #0f0069;
      }
    }

    &--large-left {
      grid-template-columns: 1fr 1fr 1fr;

      .galleryItem:nth-child(2) {
        background-color: #d2003c;
        grid-row: 1 / span 2;
        grid-column: 1 / span 2;
        height: 100%;
      }

      .galleryItem:nth-child(3) {
        background-color: #0f0069;
      }
    }
  }

  &Item {
    display: inline-grid;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #dadada;
    height: (165px/375px) * 100vw;
    min-height: 160px;
    position: relative;

    // img {
    //   height: 100%;
    //   width: 100%;
    //   object-fit: cover;
    //   object-position: center center;
    // }

    &Text {
      background: rgba(0, 0, 0, 0.5);
      bottom: 0;
      color: #fff;
      display: inline-block;
      font: 14px/1 var(--secondary-font);
      height: 25px;
      left: 0;
      line-height: 25px;
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }

  &ItemOverlay {
    background: rgba(0,0,0,0.65);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 50px 0px;

    &Container {
      background: #fff;
      // margin: 50px 30px 20px;
      margin: 0 auto;
      position: relative;

      .media {
        height: 100% !important;
      }
    }

    &Text {
      color: #fff;
      display: inline-block;
      font: 24px/1 var(--secondary-font);
      margin-top: 15px;
      text-align: center;
      width: 100%;
    }

    &Close {
      appearance: none;
      background: transparent;
      border: 0px;
      position: absolute;
      top: 10px;
      right: 15px;
      color: #fff;
      font: 16px/16px var(--primary-font);
    }
  }
}

.App--desktop {
  .galleryItem {
    height: 100% !important;
    min-height: 140px !important;
  }
}
