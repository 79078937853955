:root {
  // colors
  --primary-color: #0f0069;
  // --primary-color: #323b7c;
  --secondary-color: #e31b4d;
  --text-color: #ffffff;

  //fonts
  --primary-font: "Futura Bold", "Helvetica", Arial;
  --secondary-font: "Futura Condensed Bold", "Helvetica", Arial;
  --body-font: "Futura Book", "Helvetica", Arial;
  --light-font: "Futura Light", "Helvetica", Arial;
}
