.winner {
  margin: 30px auto 20px;
  width: calc(100% - 40px);

  h2 {
    color: #ffffff;
    font: 26px/30px var(--secondary-font);
    margin: 0 auto 10px;
    max-width: 320px;
    text-transform: uppercase;
    text-align: center;
  }

  figure {
    background: var(--secondary-color);
    height: 340px;
    margin: 0 auto;
    width: 215px;

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      width: 100%;
    }
  }
}
