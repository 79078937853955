.mediaSelect {
  text-align: center;

  &Action {
    border: 3px solid var(--secondary-color);
    border-radius: 50%;
    display: flex;
    height: 100px;
    margin: 0 auto 20px;
    width: 100px;

    &--loading {
      border: none;
      border-radius: 0;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    img {
      align-self: center;
      display: block;
      margin: 0 auto;
      width: 50px;
    }
  }

  h2 {
    color: var(--text-color);
    font: 18px/1.2 var(--body-font);
    margin: 0 0 6px;
    text-align: center;
  }

  small {
    color: var(--text-color);
    display: block;
    font: 14px/1.2 var(--body-font);
    text-align: center;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .btn {
    margin: 10px auto;
  }
}
