.stickerSelect {
  background: #ffffff;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  padding: 10px;

  &--loadingAll {
    height: 100vw;
    width: 200px;
    margin: 0 auto;
    border: none;
    border-radius: 0;

    svg {
      height: 100%;
      width: 100%;
    }
  }
  &--loadingSingle {
    height:50px;
    width: 50px;
    margin: 0 auto;
    border: none;
    border-radius: 0;

    svg {
      height: 100%;
      width: 100%;
    }
  }
  &Item {
    border-radius: 4px;
    border: 2px solid #eaeaea;
    display: flex;
    height: 135px;
    position: relative;
    align-items: center;
    justify-content: center;

    .stickerItemLabel {
      display: flex;
      height: 128px;
      left: 0;
      top: 0;
      transition: background-color 300ms ease;
      width: 128px;
      z-index: 2;
      align-items: center;
      justify-content: center;

      img {
        align-self: center;
        display: block;
        margin: 0 auto;
        max-height: calc(100% - 20px);
        max-width: calc(100% - 20px);
      }

      &:after {
        border: 2px solid var(--primary-color);
        background: #ffffff;
        border-radius: 50%;
        color: var(--primary-color);
        content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZGF0YS1uYW1lPSJMYXllciAxIiBpZD0iTGF5ZXJfMSIgdmlld0JveD0iMCAwIDY0IDY0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PHBhdGggZD0iTTIxLjMzLDU3LjgyLDAsMzYuNTNsNS44Ny01Ljg3TDIxLjMzLDQ2LjA5LDU4LjEzLDkuMzYsNjQsMTUuMjMsMjEuMzMsNTcuODIiIGRhdGEtbmFtZT0iJmx0O0NvbXBvdW5kIFBhdGgmZ3Q7IiBpZD0iX0NvbXBvdW5kX1BhdGhfIi8+PC9zdmc+');
        cursor: pointer;
        display: block;
        height: 18px;
        opacity: 0;
        padding: 3px;
        position: absolute;
        right: -9px;
        top: -9px;
        transition: opacity 300ms ease;
        width: 18px;
      }
    }

    input[type='radio'] {
      left: 50%;
      position: absolute;
      top: 50%;
      opacity: 1;
      z-index: 1;
    }

    input[type='radio']:checked ~ label.stickerItemLabel {
      background-color: #f1f1f1;

      &:after {
        opacity: 1;
      }
    }
  }
}
.hidden {
  display: none;
}
