.hero {
  position: relative;
  width: 100%;
  height: 50%;

  &Branding {
    height: 60px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 74px;
    z-index: 1;
  }

  h1 {
    bottom: 0;
    color: var(--text-color);
    font: 33px/1.2 var(--secondary-font);
    left: 50%;
    letter-spacing: -0.3px;
    margin: 0 auto 20px;
    max-width: 295px;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: calc(100% - 80px);
    z-index: 1;

  }

  &Shadow{
    position: relative;
    height: 0;
    top: -59px;
    width: calc(100% - 131px);
    margin: 0 auto;
    box-shadow: 2px 15px 54px 38px rgba(0, 0, 0, 0.3);
  }
  &Image {
    position: relative;

    img {
      width: 100%;
    }

    &:after {
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%);
      bottom: 0;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
}
