.media {
  height: calc(100vh - 104px);
  overflow: hidden;
  position: relative;

  &Image, &Video {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &Edits {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}

.App--desktop {
  .media {
    height: calc(100% - 104px) !important;
  }
}
